// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formInput{
    display: flex;
    flex-direction: column;
    /* width: 280px; */
}

/* input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}
 */

label{
    font-size: 12px;
    color: gray;
}

span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/formInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;;;;;;EAME;;AAEF;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".formInput{\n    display: flex;\n    flex-direction: column;\n    /* width: 280px; */\n}\n\n/* input{\n    padding: 15px;\n    margin: 10px 0px;\n    border-radius: 5px;\n    border: 1px solid gray;\n}\n */\n\nlabel{\n    font-size: 12px;\n    color: gray;\n}\n\nspan{\n    font-size: 12px;\n    padding: 3px;\n    color: red;\n    display: none;\n}\n\ninput:invalid[focused=\"true\"]{\n    border: 1px solid red;\n}\n\ninput:invalid[focused=\"true\"] ~ span{\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
